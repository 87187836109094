// extracted by mini-css-extract-plugin
export var badge = "footer-module--badge--19a92";
export var container__center = "footer-module--container__center--f1455";
export var container__end = "footer-module--container__end--c0bd9";
export var container__link = "footer-module--container__link--9af87";
export var container__start = "footer-module--container__start--64ee1";
export var copyrightStatement = "footer-module--copyright-statement--c4106";
export var footer = "footer-module--footer--a2fe4";
export var footer__container = "footer-module--footer__container--183dd";
export var list = "footer-module--list--6cde9";
export var logo__link = "footer-module--logo__link--db032";
export var netlifyLink = "footer-module--netlify-link--f1542";
export var note = "footer-module--note--8e913";
export var rightText = "footer-module--right-text--12058";